<template>
  <div id="app">
    <div style="height: 90px"></div>
    <div class="flex-vc">
      <div class="main-area">
        <div class="logo-row">
          <img src="@/assets/new-logo.jpg" alt="logo" class="logo-img" />
        </div>
        <div class="login-area" v-if="teamList.length !== 0">
          <div class="choose-title">请选择你的企业</div>
          <!-- <div v-show="teamList.length === 0" class="add-wrap">
                        <img
                            src="@/assets/addTeam.png"
                            alt="add"
                            class="add-img"
                        />
                        <div class="no-team-tip">你还没有企业 赶紧去创建吧</div>
                    </div> -->
          <div class="select-wrap">
            <div
              v-for="(item, index) in teamList"
              :key="index"
              class="team-row"
              :class="currentSelectIndex === index ? 'active-row' : ''"
              @click="selectTeam(index, item)"
            >
              <div class="name-row">
                <div class="name">名称：{{ item.name }}</div>
                <div v-show="item.switch" class="is-last">(上次)</div>
              </div>
              <div class="id-row">ID：{{ item.customerId }}</div>
            </div>
          </div>
          <div class="footer-btn-group">
            <div @click="joinTeam" class="empty-btn">加入已有企业</div>
            <div @click="createTeam" class="empty-btn">创建新的企业</div>
            <div
              @click="ok"
              class="ok-btn"
              :class="currentSelectItem !== '' ? 'active-ok-btn' : ''"
            >
              确认
            </div>
          </div>
        </div>
        <template v-else>
          <div v-if="isJoinTeam" class="noTeamBox">
            <div class="join-team">
              <div class="title">加入已有企业团队</div>
              <div class="code-box">
                <el-input
                  v-model="code"
                  placeholder="请输入入团口令"
                ></el-input>
                <el-button class="join-btn" @click="confirmJoin"
                  >加入</el-button
                >
              </div>
              <div class="prompt-box">
                <i class="el-icon-question"></i>
                <div class="message-box">
                  <div class="prompt">入团口令如何获取？</div>
                  <div class="message">
                    请企业创建者登录平台，进入「设置-团队管理-邀请成员」！
                  </div>
                </div>
              </div>
            </div>
            <div
              class="team-box"
              style="cursor: pointer"
              @click="isJoinTeam = false"
            >
              <div class="team-management">
                <img src="@/assets/team-management.png" alt="" />
                <div>创建企业团队</div>
              </div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div v-else class="createTeam">
            <div class="create-team">
              <div @click="isJoinTeam = true" style="cursor: pointer">
                <i class="el-icon-arrow-left"></i>
                返回
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="title">创建企业团队</div>
            </div>
            <el-form
              ref="ruleForm"
              :model="teamForm"
              :rules="rules"
              label-width="80px"
            >
              <el-form-item label="企业全称" prop="enterpris_name">
                <el-input
                  v-model="teamForm.enterpris_name"
                  autocomplete="off"
                  placeholder="请输入你所在企业名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="团队名称" prop="name">
                <el-input
                  v-model="teamForm.name"
                  autocomplete="off"
                  placeholder="请给团队起个名字"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系邮箱">
                <el-input
                  v-model="teamForm.email"
                  autocomplete="off"
                  placeholder="请输入联系邮箱"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="create-box">
              <el-button
                type="primary"
                size="medium"
                class="create-btn"
                @click="submitForm"
                >创建</el-button
              >
            </div>
          </div>
        </template>
      </div>
    </div>

    <div style="height: 110px"></div>
    <div class="copy-right">
      Copyright © 2016-2022 Mysitefy All rights reserved
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showCreate"
      width="550px"
      class="no-footer-border-top"
    >
      <div slot="title" class="create-team" style="margin-bottom: 0px">
        <div @click="isJoinTeam = true" style="cursor: pointer">
          <i class="el-icon-arrow-left"></i>
          返回
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="title">创建企业团队</div>
      </div>
      <el-form
        ref="ruleForm"
        :model="teamForm"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="企业全称" prop="enterpris_name">
          <el-input
            v-model="teamForm.enterpris_name"
            autocomplete="off"
            placeholder="请输入你所在企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="团队名称" prop="name">
          <el-input
            v-model="teamForm.name"
            autocomplete="off"
            placeholder="请给团队起个名字"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱">
          <el-input
            v-model="teamForm.email"
            autocomplete="off"
            placeholder="请输入联系邮箱"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="create-box">
        <el-button
          type="primary"
          size="medium"
          class="create-btn"
          @click="submitForm"
          >创建</el-button
        >
      </div>
      <!-- <div class="input-wrap">
                <el-input
                    v-model="teamForm.name"
                    placeholder="请给企业取个名字"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showCreate = false">取 消</el-button>
                <el-button type="primary" @click="confirmCreate"
                    >创建</el-button
                >
            </span> -->
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="showJoin"
      width="550px"
      class="no-footer-border-top"
    >
      <div slot="title" class="title">加入已有企业团队</div>
      <div class="join-team" style="padding-top: 0px">
        <div class="code-box">
          <el-input
            v-model="code"
            placeholder="请输入入团口令"
            style="width: 100%"
          ></el-input>
          <el-button class="join-btn" @click="confirmJoin">加入</el-button>
        </div>
        <div class="prompt-box">
          <i class="el-icon-question"></i>
          <div class="message-box">
            <div class="prompt">入团口令如何获取？</div>
            <div class="message">
              请企业创建者登录平台，进入「设置-团队管理-邀请成员」！
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="how-tip">
                入团口令如何获取？<span class="gray-text"
                    >请企业创建者登录平台，进入「设置-团队管理-邀请成员」！</span
                >
            </div>
            <div class="input-wrap">
                <el-input
                    v-model="code"
                    placeholder="请输入入团口令"
                ></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showJoin = false">取 消</el-button>
                <el-button type="primary" @click="confirmJoin">加入</el-button>
            </span> -->
    </el-dialog>
  </div>
</template>

<script>
import ajax from "../../js/ajax.js";
import { setStorage, getlocalStorage } from "@/utils/main.js";
import global from "../../../global.js";
export default {
  name: "App",
  components: {},
  data() {
    return {
      showJoin: false,
      showCreate: false,
      teamList: [],
      currentSelectIndex: "", // 当前选择企业的index
      currentSelectItem: "", // 当前选择的企业
      teamName: "", // 加入的企业名
      code: "", // 邀请码
      teamForm: {
        name: "",
        enterpris_name: "",
        email: "",
      },
      isJoinTeam: true,
      rules: {
        name: [
          {
            required: true,
            message: "请输入你所在的企业全称",
            trigger: "blur",
          },
        ],
        enterpris_name: [
          { required: true, message: "请输入团队名称", trigger: "blur" },
        ],
      },
      switch: false,
    };
  },
  watch: {
    "teamForm.enterpris_name"(val) {
      this.teamForm.name = val;
    },
  },
  computed: {
    url() {
      if (global.env === "dev") {
        return "http://10.10.20.228:6060";
      } else if (global.env === "test") {
        return "http://testnodeclient.linkjoint.cn";
      } else if (global.env === "local") {
        return "http://192.168.2.101:3000";
      } else {
        return "https://platform.mysitefy.com";
      }
    },
    token() {
      return getlocalStorage("token");
    },
  },
  created() {
    if (this.$route.query.token && this.$route.query.user) {
      const urlToken = this.$route.query.token;
      const user = JSON.parse(this.$route.query.user);
      setStorage("token", urlToken);
      setStorage("user", user);
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmCreate();
        } else {
          return false;
        }
      });
    },
    async getData() {
      let loading = this.$loading();
      const { data } = await ajax.get("/api/user/teamSwitchList");

      if (data.code === 200) {
        loading.close();
        this.teamList = data.data;
        this.switch = this.teamList.filter((v) => v.switch == 1).length
          ? true
          : false;
        this.selectLast();
      }
    },
    // 默认选中当前团队
    selectLast() {
      for (let i = 0; i < this.teamList.length; i++) {
        if (this.teamList[i].switch === 1) {
          this.selectTeam(i, this.teamList[i]);
          break;
        }
      }
      if (this.teamList.length !== 0 && this.switch) {
        this.ok();
      }
    },
    selectTeam(index, item) {
      this.currentSelectIndex = index;
      this.currentSelectItem = item;
    },
    joinTeam() {
      this.showJoin = true;
    },
    async confirmJoin() {
      const { data } = await ajax.post("/api/team/joinTeam", {
        invitation_code: this.code,
      });
      if (data.code === 200) {
        this.$message.success("加入成功");
        this.refreshUser();
        this.code = "";
        this.getData();
        this.showJoin = false;
      }
    },
    async confirmCreate() {
      const emailReg =
        // eslint-disable-next-line no-useless-escape
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      const isEmail = emailReg.test(this.teamForm.email);
      if (!isEmail && this.teamForm.email) {
        this.$message.error("请输入正确的邮箱");
        return;
      }
      const { data } = await ajax.post("/api/team/addTeam", {
        ...this.teamForm,
      });
      if (data.code === 200) {
        this.$message.success("创建成功");
        this.refreshUser();
        // this.teamName = "";
        Object.keys(this.teamForm).forEach((v) => (this.teamForm[v] = ""));
        this.getData();
        this.showCreate = false;
      }
    },
    createTeam() {
      this.showCreate = true;
    },

    async ok() {
      if (this.currentSelectItem.switch === 1) {
        this.refreshUser().then((res) => {
          window.open(
            `${this.url}/welcome?token=${this.token}&user=${res}`,
            "_self"
          );
        });
      } else {
        let loading = this.$loading();
        const { data } = await ajax.post("/api/team/switchTeam", {
          tid: this.currentSelectItem.tid,
        });
        if (data.code === 200) {
          loading.close();
          this.refreshUser().then((res) => {
            window.open(
              `${this.url}/welcome?token=${this.token}&user=${res}`,
              "_self"
            );
          });
        }
      }
    },
    async refreshUser() {
      const { data: user } = await ajax.get("/api/user/info");
      if (user.code === 200) {
        setStorage("user", user.data);
      }
      return JSON.stringify(user.data);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  position: relative;
  min-height: 100vh;
  background-image: url("../../assets/bg.png");
  background-repeat: repeat;
  background-size: contain;
  padding: 0 15px;
  .flex-vc {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .copy-right {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 25px;
  }
  .main-area {
    flex: 1;
    .logo-row {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 50px;
      .logo-img {
        width: 210px;
      }
    }
    .login-area {
      margin-left: auto;
      margin-right: auto;
      background-color: #fff;
      border-radius: 20px;
      max-width: 600px;
      width: 100%;
      .choose-title {
        padding: 68px 0 44px 0;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
      .add-wrap {
        background: #f4f7fc;
        border-radius: 20px;
        text-align: center;
        width: max-content;
        padding: 71px 44px 79px 44px;
        margin: 0 auto 72px auto;
        .add-img {
          width: 50px;
          margin-bottom: 24px;
        }
        .no-team-tip {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
        }
      }
      .select-wrap {
        width: 500px;
        height: 269px;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        overflow-y: scroll;
        margin: 0 auto;
        margin-bottom: 10px;
        .team-row {
          height: 49px;
          border-bottom: 1px solid #efefef;
          background: #fff;
          padding: 20px 14px;
          .name-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
              max-width: 411px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .is-last {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #4a82f4;
              line-height: 22px;
            }
          }
          .id-row {
            margin-top: 5px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
            max-width: 406px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .active-row {
          background: #f4f7fc;
          border-left: 2px solid #4a82f4;
        }
      }
      .footer-btn-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 50px 50px 0;
        .empty-btn {
          border-radius: 2px;
          border: 1px solid #d9d9d9;
          padding: 0 13px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 32px;
          margin-right: 15px;
          cursor: pointer;
        }
        .ok-btn {
          background: #cccccc;
          pointer-events: none;
          border-radius: 2px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 32px;
          text-align: center;
          padding: 0 33px;
          cursor: pointer;
        }
        .active-ok-btn {
          background: #4a82f4;
          pointer-events: unset;
        }
      }
    }
  }
}
.center {
  text-align: center;
}
.tip-text {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
}
.go-btn {
  display: inline-block;
  background: #4a82f4;
  text-decoration: none;
  padding: 0 15px;
  border-radius: 4px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 34px;
  text-align: center;
  margin-top: 25px;
}
.blue-text {
  margin-top: 25px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a82f4;
  line-height: 22px;
}
.input-wrap {
  border-bottom: 1px solid #dddddd;
  .el-input__inner {
    border: none;
    padding: 0;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000;
    line-height: 25px;
  }
  .el-input__inner::placeholder {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 25px;
  }
}
.how-tip {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin-bottom: 15px;
  margin-top: -37px;
  .gray-text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}
@media (max-width: 767px) {
  #app .main-area .login-area .vc {
    padding: 20px;
  }
  #app .copy-right {
    font-size: 12px;
  }
}

.join-team {
  background-color: #fff;
  border-radius: 12px;
  padding: 32px;
  .code-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    .el-input {
      width: 306px;
    }
    .join-btn {
      margin-left: 20px;
      background-color: rgba(75, 128, 245, 1);
      border-radius: 10px;
      border-color: rgba(75, 128, 245, 1);
      color: #fff;
    }
  }
  .prompt-box {
    background: #fffcf5;
    border: 1px solid #fdf1d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    i {
      margin-left: 21px;
      color: #f2ba49;
    }
    .message-box {
      margin: 15px 15px 15px 11px;
      font-size: 14px;
      font-family: "Noto Sans SC";
      color: #333333;
      .message {
        color: #666666;
      }
    }
  }
}
.noTeamBox {
  width: 450px;
  margin: 0 auto;
  .title {
    font-size: 18px;
  }
  .team-box {
    height: 80px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    margin-top: 20px;
    border-radius: 12px;
    padding: 20px 32px;
    font-size: 18px;
    .team-management {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
    }
  }
}
.createTeam {
  background: #ffffff;
  border-radius: 12px;
  width: 450px;
  margin: 0 auto;
  padding: 32px;
  box-sizing: border-box;
}
.create-team {
  display: flex;
  align-items: center;
  color: #333333;
  margin-bottom: 40px;
  .title {
    color: #8f8f8f;
  }
  .el-divider {
    background-color: #000;
  }
}
.create-box {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .create-btn {
    background-color: #4981f4;
    border-radius: 10px;
    border-color: #4981f4;
  }
}
</style>
